module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Open Sans","Merriweather","Roboto"]}},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"HooDievYVPCifjHTXbPFXKssS3SrQtGS","devKey":"ThisIsNotARealKey","trackPage":false,"delayLoad":false},
    },{
      plugin: require('/opt/build/repo/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
